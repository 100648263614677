export const LANGUAGE_FR = 'fr'
export const LANGUAGE_NL = 'nl'
export const LANGUAGE_EN = 'en'
export const LANGUAGE_TR = 'tr'
export const DEFAULT_LANGUAGE = LANGUAGE_EN
export const AVAILABLE_LANGUAGES = [
    LANGUAGE_FR,
    LANGUAGE_EN,
    LANGUAGE_NL,
]

export const DEFAULT_SITE_NAME = 'Betfirst'

export const MARKET_TYPE_ID_1X2 = 1
export const MARKET_TYPE_ID_TOTAL_GOALS = 2000003
export const MARKET_TYPE_ID_BOTH_TEAMS_SCORE = 1580013

export const IMAGE_LOAD_STATUS_ERROR = 'error'
export const IMAGE_LOAD_STATUS_LOADED = 'loaded'
export const IMAGE_LOAD_STATUS_LOADING = 'loading'

export const STORYBLOK_GQL_URL = 'https://gapi.storyblok.com/v1/api'

export const ANIMATION_LOGIN = 'login'
export const ANIMATION_DEPOSIT = 'deposit'
export const ANIMATION_MOBILE_CASINO = 'mobile-casino'
export const ANIMATION_MOBILE_SPORTS = 'mobile-sports'
